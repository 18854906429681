.MuiTypography-root.MuiTypography-subtitle1 {
  font-size: 1.25rem;
  font-weight: 300;
}

.MuiTypography-root.MuiTypography-body2 {
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 175%;
}

.MuiTypography-root.MuiTypography-body1 {
  font-weight: 400;
  font-size: 1rem;
}

.MuiTypography-root.MuiTypography-h3 {
  line-height: 150%;
  font-weight: 450;
}

.MuiTypography-root.MuiTypography-h4 {
  line-height: 200%;
}

.MuiTypography-root.MuiTypography-h5 {
  font-size: 1.2rem;
  font-weight: 500;
}

.MuiTypography-root.MuiTypography-h6 {
  font-size: 1rem;
  font-weight: 600;
}

#force-font-for-pdf * {
  font-family: "Arial", sans-serif;
  line-height: 125%;
  font-size: 11pt;
  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 900;
  }
}

.underline {
  text-decoration: underline;
}
